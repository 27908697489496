
class CustomerOnBoarding {
    customerName = '';
    email = '';
    contactNo = '';
    tradeInId = '';
    termsAndCond = false;
    promotion = true;
    isValidatedForm = false;
    storeName = '';
    serialNumber = '';
    deviceStorage = '';
    manufacturer = '';
    phoneModel = '';
    displayName = '';
    tagCode = '';
    phoneImei = '';
    carrier = '';
    tradeInValue = 0.0;
    invoiceNo = '';
    newDeviceIMEI = '';
    newDeviceMakeAndModel = '';
    storeEmail = '';
    newDeviceSRP='';
    token='';
    pickUpAddress = '';
}


var customerOnBoarding = new CustomerOnBoarding();
export default customerOnBoarding;

